<template>
  <div>
    <v-row v-if="missions.length > 0">
      <v-col
        md="4"
        sm="12"
        cols="12"
        v-for="mission of missions"
        :key="mission.id"
      >
        <member-mission
          v-if="mission.group && mission.group.id"
          :mission="mission"
          :showInfo="showInfo"
        ></member-mission>
        <dashboard-mission-card
          v-else
          :mission="mission"
          :copy="copy"
          :update="update"
          :del="del"
        ></dashboard-mission-card>
      </v-col>
    </v-row>
    <div v-else class="text-center py-4">
      <h1 class="heading app-dark-gray--text font-weight-light">
        {{ $t('missions.noMissionsFound') }}
      </h1>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DashboardMissionCard from './DashboardMissionCard';
import GroupMissionInfoModal from '../groups/missions/GroupMissionInfoModal';
import MemberMission from '../group-members/missions/MemberMission';
import {
  COPY_MISSION,
  UPDATE_MISSION,
  DELETE_MISSION
} from '@/store/missions/types';
import missionsMapper from '../../mappers/missions.mapper';

const GROUP_MISSION_INFO_MODAL_CONFIG = {
  width: '400px'
};

export default {
  name: 'missions',
  components: {
    DashboardMissionCard,
    MemberMission
  },
  computed: {
    ...mapGetters(['missions'])
  },
  methods: {
    async copy(payload) {
      await this.$store.dispatch(COPY_MISSION, payload);

      this.$notify({
        type: 'success',
        text: this.$i18n.t('missions.missionClonedSuccessfully')
      });
    },
    async update(payload) {
      const missionToUpdate = missionsMapper.getMissionUpdateReqBody(payload);

      await this.$store.dispatch(UPDATE_MISSION, missionToUpdate);

      this.$notify({
        type: 'success',
        text: this.$i18n.t('missions.missionUpdatedSuccessfully')
      });
    },
    showInfo(mission) {
      this.$root.$modal.show(
        GroupMissionInfoModal,
        {
          mission
        },
        GROUP_MISSION_INFO_MODAL_CONFIG
      );
    },
    async del(payload) {
      await this.$store.dispatch(DELETE_MISSION, payload);

      this.$notify({
        type: 'success',
        text: this.$i18n.t('missions.missionDeletedSuccessfully')
      });
    }
  }
};
</script>
