<template>
  <v-container fluid grid-list-md class="pa-4">
    <v-row v-if="$vuetify.breakpoint.smAndDown" class="hidden-md-and-up mb-3">
      <v-col cols="12">
        <user-profile-card></user-profile-card>
        <div class="text-center mt-3 mb-2">
          <support-us></support-us>
        </div>
        <div class="text-center">
          <feedback></feedback>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="border-top-blue mb-3" tile>
          <v-card-title
            class="text-h5 app-dark-gray--text font-weight-light py-2"
          >
            <v-icon left>mdi-calendar</v-icon>
            <span>{{ $t('missions.myMissions') }}</span>
            <v-spacer></v-spacer>
            <v-btn dark fab small depressed @click="open()" color="app-blue">
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
        <missions></missions>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserProfileCard from '@/components/users/UserProfileCard';
import SupportUs from '@/components/shared/SupportUs';
import Feedback from '@/components/shared/Feedback';
import Missions from '@/components/missions/Missions';
import ManageMissionModal from '@/components/missions/ManageMissionModal';
import { ADD_MISSION, GET_MISSIONS } from '@/store/missions/types';
import dailyCronMixin from '../mixins/daily.cron.mixin';

export default {
  name: 'missions-view',
  components: {
    UserProfileCard,
    SupportUs,
    Feedback,
    Missions
  },
  mixins: [dailyCronMixin],
  data() {
    return {
      dailyCronWatcher: null
    };
  },
  async created() {
    this.watchDailyCron();
    await this.$store.dispatch(GET_MISSIONS);
  },
  beforeDestroy() {
    this.dailyCronWatcher();
  },
  methods: {
    async open() {
      const payload = await this.$root.$modal.show(ManageMissionModal);

      if (!payload) return;

      await this.$store.dispatch(ADD_MISSION, payload);
    },
    watchDailyCron() {
      this.dailyCronWatcher = this.$store.watch(
        (state, getters) => getters.cronData,
        async cronData => {
          if (!cronData) return;

          await this.$store.dispatch(GET_MISSIONS);
        }
      );
    }
  }
};
</script>
